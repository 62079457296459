<template>
<div class="wrap">
  <pageHead></pageHead>
  <div class="indexCarousel">
    <el-carousel :height="screenWidth*0.521+'px'" v-if="screenWidth>700">
      <el-carousel-item v-for="(item,index) in imgData.topImage" :key="index">
        <el-image
            style="width: 100%; height: auto"
            :src="item"
            fit="cover"></el-image>
      </el-carousel-item>
    </el-carousel>
    <div class="indexAppTop" v-else :style="{height:screenHeight+'px',width: screenWidth+'px'}">
      <el-image :src="imgData.topAppPage" :style="{height:screenHeight+'px',width: screenWidth+'px'}" ></el-image>
      <div class="indexAppButtonRow">
        <div class="indexAppButton">
          Android下载
        </div>
        <div class="indexAppButton">
          iOS下载
        </div>
      </div>
    </div>
  </div>
  <div class="warp indexWarp">
    <div class="indexTitleBox box">
      <div class="indexTitle">倾行APP</div>
      <div class="indexSubtitle">
        致力于线上健康服务产业以及一站式运动解决平台打造
        为用户量身定制健身课程。
      </div>
      <div class="more" @click="toPage('AppPage')">
        了解更多
      </div>
    </div>
  </div>
  <div class="warp indexWarpTwo">
    <div class="box indexBoxTwo">
      <div class="swiper mySwiper" v-show="screenWidth>=1200">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item,index) in imgData.appPage" :key="index">
            <div style="width: 100%">
              <img style="width: 100%; height: auto;display: block"
                  :src="item"/>
            </div>
          </div>
        </div>
        <div class="swiper-pagination"></div>
      </div>
      <el-carousel  type="card"  v-if="screenWidth<1200" :height="screenWidth>1200?'800px':(((screenWidth/3)*2.17)+'px')">
        <el-carousel-item v-for="(item,index) in imgData.appPage" :key="index">
          <el-image
              style="width: 100%; height: 100%"
              :src="item"
              fit="contain"></el-image>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
  <div class="warp indexWarp">
    <div class="indexTitleBox box">
      <div class="indexTitle">智能设备</div>
      <div class="indexSubtitle">
        倾行旗下品牌智能运动产品，记录用户运动数据
        让用户可以直白地了解身体变化。
      </div>
      <div class="more" @click="toPage('equipment')">
        了解更多
      </div>
    </div>
  </div>
  <div class="indexWarpThree warp">
    <div class="box indexBoxThree">
      <div class="indexGoods" v-for="(item,index) in imgData.goodsPage" :key="index" :style="{width: screenWidth>750?'30%':'100%'}">
        <el-image
            style="width: 100%; height: 100%"
            :src="item.image"
            fit="contain"></el-image>
        <div class="indexGoodsTitle">
          {{item.title}}
        </div>
        <div class="indexGoodsSubtitle">
          {{item.subtitle}}
        </div>
      </div>
    </div>
  </div>
  <div class="warp indexWarp">
    <div class="indexTitleBox box">
      <div class="indexTitle">企业文化</div>
      <div class="indexSubtitle">
        因陪伴而执着，因执着而出色
      </div>
    </div>
  </div>
  <div class="warp indexWarp">
    <div class="box indexBox" style="padding:0 20px 120px;display: flex;flex-wrap: wrap">
      <img
          class="indexBottomImage"
          v-for="(item,index) in imgData.aboutPage"
          :key="index"
          style="width: 25%; height: auto;display: block"
          :src="item"/>
    </div>
  </div>

  <pageFoot></pageFoot>
</div>
</template>

<script>
import pageHead from "@/views/assembly/pageHead/pageHead";
import pageFoot from "@/views/assembly/pageFoot/pageFoot";
import Swiper from 'swiper';
import 'swiper/swiper-bundle.css';

export default {
  data(){return{
    show3: true,
    screenWidth:document.documentElement.clientWidth,
    screenHeight:document.documentElement.clientHeight,
    imgData:this.$store.state.pageInfo.indexPage
  }},
  components:{
    pageHead,
    pageFoot
  },
  mounted() {
    this.$nextTick(()=>{
      new Swiper(".mySwiper", {
        slidesPerView: 5,
        spaceBetween: 30,
        loop: true,
        freeMode: true,
        speed: 100,
        autoplay: { //匀速滚动
          delay: 2500, //间隔时间0秒
          disableOnInteraction: false, //触摸后仍然执行
        },
        paginationClickable: true,
        centeredSlides: true,
        autoplayDisableOnInteraction: false,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      });

    })
    const that = this
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.documentElement.clientWidth
        window.screenHeight = document.documentElement.clientHeight

        that.screenHeight = window.screenHeight
        that.screenWidth = window.screenWidth

      })()
    }
  },
  methods:{
    toPage(e){
      this.$router.push(e);
    }
  }
}
</script>

<style>
.mySwiper .swiper-wrapper {
    transition-timing-function: linear !important;
  }
.transition-box {
  margin-bottom: 10px;
  width: 200px;
  height: 100px;
  border-radius: 4px;
  background-color: #409EFF;
  text-align: center;
  color: #fff;
  padding: 40px 20px;
  box-sizing: border-box;
  margin-right: 20px;
}
.indexCarousel{
  position: relative;
}
.indexTitleBox{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 2.17%;
}
.indexTitle{
  font-size: 30px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
}
.indexSubtitle{
  font-size: 18px;
  max-width: 392px;
  padding: 0 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #888888;
  text-align: center;
  margin:0.8% auto 2%;
}
.more{
  border: 1px solid #666666;
  border-radius: 20px;
  padding: 5px 18px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  cursor: pointer;
}
.indexWarpTwo{
  background: #F7F7F7;
  -webkit-touch-callout: none;

  -webkit-user-select: none;

  -khtml-user-select: none;

  -moz-user-select: none;

  -ms-user-select: none;

  user-select: none;
}
.indexBoxTwo{
  padding-top: 30px;
}
.swiper-slide{
  padding-top: 30px;
  transform:scale(0.9);
  transition:transform 0.4s ease-out;
}
.swiper-slide-active{
  transform:scale(1);
  /*height: 516px;*/
  padding-top: 10px;
}

.indexWarpThree{
  background: #F7F7F7;
}
.indexBoxThree{
  background: #F7F7F7;
  padding: 2.4% 5% 2.7%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.indexGoodsTitle{
  text-align: center;
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 82px;
}
.indexGoodsSubtitle{
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  text-align: center;
  height: 50px;
}
.indexBottomImage:hover{
  transition:transform 0.4s ease-out;
  transform:scale(1.5);
}
.indexAppButtonRow{
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  padding: 0 5% 20%;
  width: 90%;
  margin: 0 auto;
  bottom: 0;
}
.indexAppButton{
  background: #F8AA44;
  border-radius: 35px;
  width: 47%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #FFFFFE;
}
</style>